import React, { FunctionComponent, useEffect, useState } from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router';
import { ToastContainer } from 'react-toastify';
import AuthLayout from '../Layouts/AuthLayout/AuthLayout';
import linksConstants from '../../config/app/linksConstants';
import SignedInLayout from '../Layouts/SignedInLayout/SignedInLayout';
import app from '../../config/app/app';
import Splashscreen from '../Shared/Loading/Splashscreen';
import { authOperations } from '../../store/Auth';
import { useDispatch } from 'react-redux';
import CloseToastButton from '../Shared/Toast/CloseToastButton';
import FullPageLayout from '../Layouts/FullPageLayout/FullPageLayout';
import { connectToUserChannel } from '../../utils/webSocket';
import { useTypedSelector } from '../../utils';
import { useTranslation } from 'react-i18next';
import InactivityAutoLogOutWindow from '../Layouts/Windows/InactivityAutoLogOutWindow';
import nes from '@hapi/nes/lib/client';
import { usePageViews } from '../../hooks/usePageChangeWatcher';
import PublicLayout from '../Layouts/PublicLayout/PublicLayout';
import { setHeaderTokenValue } from '../../utils/httpRequest';

type AppProps = {};

const App: FunctionComponent<AppProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  usePageViews();
  const [loading, setLoading] = useState(true);
  const authData = useTypedSelector((state) => state.auth);

  const onIframeMessage = (e: MessageEvent) => {
    if (e.data && e.data.command === 'auth' && e.data.token) {
      setHeaderTokenValue(e.data.token);
      dispatch(authOperations.validateToken({ cookie: false }));
    }
  };

  useEffect(() => {
    try {
      window.addEventListener('message', onIframeMessage, false);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    let wsClient: nes.Client | null = null;

    const connectToWebsocket = async () => {
      if (authData.authenticated) {
        wsClient = await connectToUserChannel(authData.user, authData.token, dispatch, t);
        if (window.Beacon && authData.chatId) {
          window.Beacon('init', authData.chatId);
        }
      } else {
        if (window.Beacon) {
          window.Beacon('destroy');
        }
        if (wsClient) {
          try {
            wsClient.disconnect();
          } catch (e) {
            // does not matter
          }
        }
      }
    };

    connectToWebsocket();
  }, [authData.authenticated]);

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      try {
        await dispatch(authOperations.validateToken({ cookie: true }));
      } catch (e) {
        console.warn('Authorization error', e);
      } finally {
        if (!didCancel) {
          setLoading(false);
        }
      }
    };

    // Do not check try to authenticate cookie if the path page is doapp_merchant_dashboard
    if (window.location.pathname.includes('/doapp_merchant_dashboard')) {
      setLoading(false);
    } else {
      fetchData();
    }
    return () => {
      didCancel = true;
    };
  }, []);

  const inactivityWindow = <InactivityAutoLogOutWindow />;
  if (loading) {
    return (
      <>
        <Splashscreen />
        {inactivityWindow}
      </>
    );
  }

  return (
    <>
      <Switch>
        <Route path={`/(${app.authLayoutRoutes.join('|')})`} component={AuthLayout} />
        <Route path={`/(${app.signedInLayoutRoutes.join('|')})`} component={SignedInLayout} />
        <Route path={`/(${app.fullPageLayoutRoutes.join('|')})`} component={FullPageLayout} />
        <Route path={`/(${app.publicPageLayoutRoutes.join('|')})`} component={PublicLayout} />
        <Redirect to={linksConstants.AUTH.LOGIN} />
        <ToastContainer closeButton={<CloseToastButton />} limit={1} newestOnTop />
      </Switch>

      {inactivityWindow}
    </>
  );
};

export default App;
