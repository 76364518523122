import React, { useState } from 'react';
import { Button, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import { sleep } from '../../../utils';
import useViewportSize from '../../../hooks/useViewportSize';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  CloseButton,
  DialogActionsStyled,
  DialogContentStyled,
  DialogHeaderStyled,
  DialogStyled
} from './WindowStyledComponents';
import CloseIcon from '@material-ui/icons/Close';

export type ModalFormWindowProps = {
  open: boolean;
  withCloseButton?: boolean;
  autocomplete?: boolean;
  onCloseClick: () => void;
  onSubmit: (props: any) => any;
  formInputs: (props: FormikProps<any>) => JSX.Element;
  initialValues: any;
  validationSchema: any;
  header: string | JSX.Element;
  okButtonText?: string | JSX.Element;
  forceDisableSubmit?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  forceSubmitToBeUnlocked?: boolean;
  ignoreDirtyValidation?: boolean;
};

const ModalFormWindow: React.FC<ModalFormWindowProps> = ({
  open,
  onCloseClick,
  onSubmit,
  formInputs,
  initialValues,
  validationSchema,
  header,
  okButtonText,
  forceDisableSubmit,
  maxWidth,
  forceSubmitToBeUnlocked,
  ignoreDirtyValidation
}) => {
  const { t } = useTranslation();
  const { smartphone } = useViewportSize();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<NetworkError | null>(null);

  const onClose = async (timeout = 200) => {
    onCloseClick();
    await sleep(timeout);
    setError(null);
    setLoading(false);
  };

  const isDisabled = (props: FormikProps<any>) => {
    if (forceSubmitToBeUnlocked) {
      return false;
    }
    if (forceDisableSubmit) {
      return true;
    }

    return props.isSubmitting || !props.isValid || (!ignoreDirtyValidation && !props.dirty);
  };

  const renderForm = (props: FormikProps<any>) => {
    return (
      <>
        <DialogHeaderStyled>
          {header}
          <CloseButton aria-label="close" onClick={async () => !loading && (await onClose())}>
            <CloseIcon />
          </CloseButton>
        </DialogHeaderStyled>
        <DialogContentStyled>
          <DialogForm>
            <Form>
              {formInputs(props)}
              <input
                disabled={props.isSubmitting || !props.isValid || (!ignoreDirtyValidation && !props.dirty)}
                type="submit"
                style={{ display: 'none' }}
              />
            </Form>
          </DialogForm>
        </DialogContentStyled>
        <DialogActionsStyled>
          <Button variant="outlined" onClick={async () => !loading && (await onClose())} size="medium">
            {t('common.cancel')}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={props.submitForm}
            size="medium"
            disabled={isDisabled(props)}
          >
            {okButtonText || t('common.ok')}
          </Button>
        </DialogActionsStyled>
      </>
    );
  };

  return (
    <DialogStyled
      open={open}
      scroll="paper"
      disableBackdropClick
      disableEscapeKeyDown={loading}
      fullScreen={smartphone}
      onEscapeKeyDown={async () => !loading && (await onClose())}
      maxWidth={maxWidth}
    >
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {renderForm}
      </Formik>
    </DialogStyled>
  );
};

const DialogForm = styled.div`
  padding: 1.2rem;
`;

export default ModalFormWindow;
