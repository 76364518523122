import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalConfirmationWindow from '../../../Shared/Window/ModalConfirmationWindow';
import Toast from '../../../Shared/Toast/Toast';
import { DoAppMerchant, MerchantRewardConfigurationType } from '../../../../store/DoApp/Merchants/types';
import { Typography } from '@material-ui/core';
import { doAppMerchantsOperations } from '../../../../store/DoApp/Merchants';
import { Row } from '../../../Shared/StyledComponents';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import styled from 'styled-components';

type PromotionalCreditsConfigurationWindowProps = {
  open: boolean;
  merchant: DoAppMerchant;
  onCloseClick: () => void;
  onSubmit?: (merchant: DoAppMerchant) => void;
  fullScreenOnMobile: boolean;
};

const PromotionalCreditsConfigurationWindow: FunctionComponent<PromotionalCreditsConfigurationWindowProps> = ({
  onCloseClick,
  onSubmit,
  merchant,
  open
}) => {
  const { t } = useTranslation();

  const onFormSubmit = async (value: MerchantRewardConfigurationType) => {
    try {
      const response = await doAppMerchantsOperations.merchantSelfConfiguration({
        rewardConfigurationType: value
      });
      Toast.success(t('notifications.promotionalCreditsConfiguration.successOperation'));
      onSubmit && onSubmit(response.data);
    } catch (e) {
      Toast.error(t('notifications.promotionalCreditsConfiguration.errorOperation'));
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.myOrganization.windows.promotionalCreditsConfiguration.title')}
      okButtonText={t('common.ok')}
      hideActionButtons
      onCloseClick={onCloseClick}
    >
      <Row>
        <Typography variant="body2">
          {t('pages.myOrganization.windows.promotionalCreditsConfiguration.content')}
        </Typography>
      </Row>

      <OptionBlock
        selected={merchant.rewardsConfigurationType === MerchantRewardConfigurationType.INVOICE_AUTO_APPLY}
        onClick={() => onFormSubmit(MerchantRewardConfigurationType.INVOICE_AUTO_APPLY)}
      >
        <MonetizationOnIcon fontSize="large" />
        <div>
          <Typography variant="body1">
            {t(
              'pages.myOrganization.windows.promotionalCreditsConfiguration.rewardConfigurationType.INVOICE_AUTO_APPLY.title'
            )}
          </Typography>
          <Typography variant="body2">
            {t(
              'pages.myOrganization.windows.promotionalCreditsConfiguration.rewardConfigurationType.INVOICE_AUTO_APPLY.description'
            )}
          </Typography>
        </div>
      </OptionBlock>

      <OptionBlock
        selected={merchant.rewardsConfigurationType === MerchantRewardConfigurationType.MANUAL_REDEEM}
        onClick={() => onFormSubmit(MerchantRewardConfigurationType.MANUAL_REDEEM)}
      >
        <AccountBalanceIcon fontSize="large" />
        <div>
          <Typography variant="body1">
            {t(
              'pages.myOrganization.windows.promotionalCreditsConfiguration.rewardConfigurationType.MANUAL_REDEEM.title'
            )}
          </Typography>
          <Typography variant="body2">
            {t(
              'pages.myOrganization.windows.promotionalCreditsConfiguration.rewardConfigurationType.MANUAL_REDEEM.description'
            )}
          </Typography>
        </div>
      </OptionBlock>
    </ModalConfirmationWindow>
  );
};

const OptionBlock = styled.div<{ selected: boolean }>`
  cursor: pointer;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  padding: 1rem;
  font-size: 0.9rem;
  border-radius: 6px;
  color: #000000;
  display: flex;
  align-items: center;

  ${({ selected }) => (selected ? `border: 4px solid #24B78A;` : '')}

  svg {
    margin-right: 12px;
  }

  &:hover {
    background: #f1f1f1;
  }
`;

export default PromotionalCreditsConfigurationWindow;
