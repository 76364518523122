import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Toast from '../../../Shared/Toast/Toast';
import { DoAppMerchant } from '../../../../store/DoApp/Merchants/types';
import { InputAdornment, Typography } from '@material-ui/core';
import { doAppMerchantsOperations } from '../../../../store/DoApp/Merchants';
import { Row } from '../../../Shared/StyledComponents';
import ModalFormWindow from '../../../Shared/Window/ModalFormWindow';
import { AdminTextInput } from '../../Admin/Shared/AdminFormInputs';
import { FormikProps } from 'formik';

type RedeemPromotionalCreditsWindowProps = {
  open: boolean;
  merchant: DoAppMerchant;
  onCloseClick: () => void;
  fullScreenOnMobile: boolean;
  onSuccessfulRedeem: () => void;
};

enum RedeemPromotionalCreditsStep {
  FORM = 'FORM',
  CONFIRMATION = 'CONFIRMATION'
}

const RedeemPromotionalCreditsWindow: FunctionComponent<RedeemPromotionalCreditsWindowProps> = ({
  onCloseClick,
  merchant,
  open,
  onSuccessfulRedeem
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [step, setStep] = React.useState<RedeemPromotionalCreditsStep>(RedeemPromotionalCreditsStep.FORM);

  const onSubmit = async (values: { amount: number }) => {
    if (step === RedeemPromotionalCreditsStep.FORM) {
      setStep(RedeemPromotionalCreditsStep.CONFIRMATION);
    }

    if (step === RedeemPromotionalCreditsStep.CONFIRMATION) {
      setLoading(true);
      try {
        await doAppMerchantsOperations.redeemPromotionalCredits({
          amount: values.amount * 100
        });
        Toast.success(t('notifications.redeemPromotionalCredits.success'));

        onCloseClick();
        onSuccessfulRedeem();
      } catch (e) {
        Toast.error(t('notifications.redeemPromotionalCredits.error'));
      } finally {
        setLoading(false);
      }
    }
  };

  const formInputs = (props: FormikProps<any>) => (
    <>
      <Row>
        <Typography variant="body1">{t('pages.myOrganization.windows.redeemPromotionalCredits.content')}</Typography>
      </Row>
      <AdminTextInput t={t} name="email" disabled section="myOrganization.windows.redeemPromotionalCredits" />
      <AdminTextInput
        inputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>
        }}
        t={t}
        name="amount"
        type="number"
        disabled={loading || step === RedeemPromotionalCreditsStep.CONFIRMATION}
        section="myOrganization.windows.redeemPromotionalCredits"
      />
      {step === RedeemPromotionalCreditsStep.CONFIRMATION && (
        <Row>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            {t('pages.myOrganization.windows.redeemPromotionalCredits.areYouSure')}
          </Typography>
        </Row>
      )}
    </>
  );

  const getOkButtonText = () => {
    if (step === RedeemPromotionalCreditsStep.FORM) {
      return t('common.redeem');
    }

    return loading ? t('common.processing') : t('common.yesIConfirm');
  };

  return (
    <ModalFormWindow
      open={open}
      header={t('pages.myOrganization.windows.redeemPromotionalCredits.title')}
      okButtonText={getOkButtonText()}
      onCloseClick={onCloseClick}
      forceDisableSubmit={loading}
      ignoreDirtyValidation
      onSubmit={onSubmit}
      formInputs={formInputs}
      initialValues={{
        amount: Math.floor(merchant.promotionalCredits / 100),
        email: merchant.email
      }}
      validationSchema={Yup.object().shape({
        amount: Yup.number().min(1).max(2000).required(),
        email: Yup.string().email().required()
      })}
    />
  );
};

export default RedeemPromotionalCreditsWindow;
