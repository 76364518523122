import React, { FunctionComponent, useEffect, useState } from 'react';
import { PageContainer } from '../PageStyledComponents';
import { Grid } from '@material-ui/core';
import Loader from '../../Shared/Loading/Loader';
import { useTypedSelector } from '../../../utils';
import OrganizationMerchant from '../MyOrganization/Partials/OrganizationMerchant';

const DoAppMerchantDashboardPublicPage: FunctionComponent = () => {
  const authenticated = useTypedSelector((state) => state.auth.authenticated);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (authenticated) {
      setLoading(false);
    }
  }, [authenticated]);

  return (
    <PageContainer>
      <Grid container spacing={3}>
        {loading && <Loader />}
        {!loading && <OrganizationMerchant />}
      </Grid>
    </PageContainer>
  );
};

export default DoAppMerchantDashboardPublicPage;
