import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import WarningIcon from '@material-ui/icons/Warning';
import {
  DoAppInvoiceData,
  DoAppInvoiceDateRange,
  DoAppMerchant,
  PaymentProcessorChargeEntry
} from '../../../../../store/DoApp/Merchants/types';
import { doAppMerchantsOperations } from '../../../../../store/DoApp/Merchants';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import {
  Box,
  Button,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import Toast from '../../../../Shared/Toast/Toast';
// import { endOfMonth, startOfMonth, subMonths } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DoAppGenerateInvoiceWindow from './DoAppGenerateInvoice';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import InfoBox from '../../../../Shared/InfoBox/InfoBox';

type DoAppInvoicePreviewWindowProps = {
  open: boolean;
  merchant: DoAppMerchant | null;
  onCloseClick: () => void;
  onFormSubmit?: () => void;
  fullScreenOnMobile: boolean;
};

const DoAppInvoicePreviewWindow: FunctionComponent<DoAppInvoicePreviewWindowProps> = ({
  merchant,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const [invoiceData, setInvoiceData] = useState<DoAppInvoiceData | null>(null);
  const [runningPromotionCharges, setRunningPromotionCharges] = useState<PaymentProcessorChargeEntry[]>([]);
  const [claimedPromotionCharges, setClaimedPromotionCharges] = useState<PaymentProcessorChargeEntry[]>([]);
  const [weeklyNewsletterCharges, setWeeklyNewsletterCharges] = useState<PaymentProcessorChargeEntry[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<DoAppInvoiceDateRange>(DoAppInvoiceDateRange.THIS_MONTH);
  const [customDateFrom, setCustomDateFrom] = useState<Date | null>(null);
  const [customDateTo, setCustomDateTo] = useState<Date | null>(null);
  const [generateInvoiceWindowOpen, onGenerateInvoiceWindowOpen, onGenerateInvoiceWindowClose] = useOpenHandler();

  useEffect(() => {
    if (open && merchant) {
      fetchInvoiceData(dateRange);
    }
  }, [open, dateRange]);

  const fetchInvoiceData = async (dateRange: DoAppInvoiceDateRange) => {
    if (!merchant) return;

    try {
      setLoading(true);
      const res = await doAppMerchantsOperations.getInvoiceResources(merchant.id, dateRange);
      const invoice = res.data;
      setInvoiceData(invoice);
      setRunningPromotionCharges(
        invoice.charges.filter((charge) => charge.description.startsWith('Running Promotion'))
      );
      setClaimedPromotionCharges(
        invoice.charges.filter((charge) => charge.description.startsWith('Promotion Claimed'))
      );
      setWeeklyNewsletterCharges(
        invoice.charges.filter((charge) => charge.description.startsWith('Promotion Weekly Newsletter'))
      );

      calculateTotal(invoice);
    } catch (e) {
      Toast.error(t('notifications.common.errorOperation'));
    } finally {
      setLoading(false);
    }
  };

  const calculateTotal = (invoice: DoAppInvoiceData) => {
    const totalCharges = invoice.charges.reduce((sum, charge) => sum + charge.amount, 0);
    if (totalCharges <= 0) {
      setTotal(0);
    } else {
      setTotal(totalCharges / 100);
    }
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.doApp.merchants.windows.invoicePreview.header')}
      hideActionButtons
      onCloseClick={onCloseClick}
      fullWidth
    >
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={2}>
        <div>
          <FormControl variant="outlined" size="small">
            <Select
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value as DoAppInvoiceDateRange)}
              variant="outlined"
            >
              <MenuItem value={DoAppInvoiceDateRange.THIS_MONTH}>
                {t('pages.doApp.merchants.windows.invoicePreview.thisMonth')}
              </MenuItem>
              <MenuItem value={DoAppInvoiceDateRange.LAST_MONTH}>
                {t('pages.doApp.merchants.windows.invoicePreview.lastMonth')}
              </MenuItem>
              {/* <MenuItem value={DoAppInvoiceDateRange.CUSTOM}>
                {t('pages.doApp.merchants.windows.invoicePreview.customDateRange')}
              </MenuItem> */}
            </Select>
          </FormControl>
          {dateRange === DoAppInvoiceDateRange.CUSTOM && (
            <Box display="flex" alignItems="center" mt={2}>
              <DatePicker
                selected={customDateFrom}
                onChange={(date) => setCustomDateFrom(date)}
                selectsStart
                startDate={customDateFrom}
                endDate={customDateTo}
                customInput={
                  <TextField
                    variant="outlined"
                    fullWidth
                    label={t('pages.doApp.merchants.windows.invoicePreview.selectDateFrom')}
                    size="small"
                  />
                }
              />
              &nbsp;
              <DatePicker
                selected={customDateTo}
                onChange={(date) => setCustomDateTo(date)}
                selectsEnd
                startDate={customDateFrom}
                endDate={customDateTo}
                customInput={
                  <TextField
                    variant="outlined"
                    fullWidth
                    label={t('pages.doApp.merchants.windows.invoicePreview.selectDateTo')}
                    size="small"
                  />
                }
              />
            </Box>
          )}
        </div>
        {total > 0 && merchant && merchant.paymentMethodActive && (
          <Button onClick={onGenerateInvoiceWindowOpen} variant="contained" color="primary">
            {t('pages.doApp.merchants.windows.invoicePreview.generate')}
          </Button>
        )}
      </Box>
      <Divider />
      {!loading && merchant && !merchant.paymentMethodActive && (
        <InfoBox type="warning">
          <WarningIcon />{' '}
          <Typography variant="caption" style={{ verticalAlign: 'super' }}>
            {t('pages.doApp.merchants.windows.invoice.merchantHasNoPaymentMethod')}
          </Typography>
        </InfoBox>
      )}
      {!loading && invoiceData && invoiceData.charges.length === 0 && (
        <InfoBox type="success">
          <DoneAllIcon />{' '}
          <Typography variant="caption" style={{ verticalAlign: 'super' }}>
            {t('pages.doApp.merchants.windows.invoice.noCharges')}
          </Typography>
        </InfoBox>
      )}
      {!loading && invoiceData && (
        <Row>
          <InfoBox type="outlinedInfo">
            <Typography variant="h6">{t('pages.doApp.merchants.windows.invoicePreview.availableCredits')}:</Typography>
            <Typography variant="body2">
              {t('pages.myOrganization.merchant.incentiveCredits')}:{' '}
              <Strong>${(invoiceData.credits.incentive / 100).toFixed(2)}</Strong>
            </Typography>
            <Typography variant="body2">
              {t('pages.myOrganization.merchant.promotionalCredits')}:{' '}
              <Strong>${(invoiceData.credits.promotional / 100).toFixed(2)}</Strong>
            </Typography>
          </InfoBox>
        </Row>
      )}
      {!loading && invoiceData && invoiceData.charges.length > 0 && (
        <Table size="small" style={{ marginTop: 15, marginBottom: 15 }}>
          <TableHead style={{ backgroundColor: '#f6f6f6' }}>
            <TableRow>
              <TableCell>
                <Typography variant="h6">{t('pages.doApp.merchants.windows.invoice.description')}</Typography>
              </TableCell>
              <TableCell style={{ textAlign: 'right' }}>
                <Typography variant="h6">{t('pages.doApp.merchants.windows.invoice.amount')}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Running Promotions Section */}
            {runningPromotionCharges.length > 0 && (
              <>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography variant="body2" color="primary">
                      <Strong>{t('pages.doApp.merchants.windows.invoice.runningPromotions')}</Strong>
                    </Typography>
                  </TableCell>
                </TableRow>
                {runningPromotionCharges.map((charge, index) => (
                  <TableRow key={`running-${index}`}>
                    <TableCell>
                      <Typography variant="body2">{charge.description}</Typography>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                      <Typography variant="body2">${(charge.amount / 100).toFixed(2)}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}

            {/* Promotion Claims Section */}
            {claimedPromotionCharges.length > 0 && (
              <>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography variant="body2" color="primary">
                      <Strong>{t('pages.doApp.merchants.windows.invoice.promotionClaims')}</Strong>
                    </Typography>
                  </TableCell>
                </TableRow>
                {claimedPromotionCharges.map((charge, index) => (
                  <TableRow key={`claim-${index}`}>
                    <TableCell>
                      <Typography variant="body2">{charge.description}</Typography>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                      <Typography variant="body2">${(charge.amount / 100).toFixed(2)}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}

            {/* Weekly Newsletters Section */}
            {weeklyNewsletterCharges.length > 0 && (
              <>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography variant="body2" color="primary">
                      <Strong>{t('pages.doApp.merchants.windows.invoice.weeklyNewsletters')}</Strong>
                    </Typography>
                  </TableCell>
                </TableRow>
                {weeklyNewsletterCharges.map((charge, index) => (
                  <TableRow key={`newsletter-${index}`}>
                    <TableCell>
                      <Typography variant="body2">{charge.description}</Typography>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                      <Typography variant="body2">${(charge.amount / 100).toFixed(2)}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}

            {/* Total Row */}
            <TableRow>
              <TableCell style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <Typography variant="h6">
                  <Strong>{t('pages.doApp.merchants.windows.invoice.total')}</Strong>
                </Typography>
              </TableCell>
              <TableCell
                style={{ whiteSpace: 'nowrap', textAlign: 'right', borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}
              >
                <Typography variant="h6">
                  <Strong>${total.toFixed(2)}</Strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {loading && <Row>{t('pages.doApp.merchants.windows.invoice.loading')}</Row>}
      {merchant && (
        <DoAppGenerateInvoiceWindow
          open={generateInvoiceWindowOpen}
          merchantId={merchant.id}
          invoiceDateRange={dateRange}
          onCloseClick={() => {
            fetchInvoiceData(dateRange);
            onGenerateInvoiceWindowClose();
          }}
          fullScreenOnMobile={false}
        />
      )}
    </ModalConfirmationWindow>
  );
};

export default DoAppInvoicePreviewWindow;
