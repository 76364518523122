import { createOrderArray, httpRequest } from '../../../utils';
import {
  DoAppInvoiceDateRange,
  DoAppMerchantsIndexResponse,
  GetInvoiceResourcesResponse,
  GetMerchantResponse,
  MerchantSelfConfigurationRequestDto,
  UpdateMerchantRequestBody
} from './types';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../rootReducer';
import { doAppMerchantsActions, doAppMerchantsRequests } from './index';

export const index = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { doAppMerchants } = getState();

  try {
    dispatch(doAppMerchantsActions.doAppMerchantsIndexBegin());

    const response = await indexRaw(
      doAppMerchants.pagination.page,
      doAppMerchants.pagination.per,
      doAppMerchants.pagination.q,
      doAppMerchants.tableSearch.order
    );

    dispatch(doAppMerchantsActions.doAppMerchantsIndexSuccess(response));
  } catch (e) {
    dispatch(doAppMerchantsActions.doAppMerchantsIndexFailed(e));
  }
};

export const indexRaw = (page: number, per: number, q: string | null, order: any) => {
  return httpRequest.runRequest<DoAppMerchantsIndexResponse>(
    doAppMerchantsRequests.index({
      requestParams: {
        page: page,
        per: per,
        q: q,
        order: createOrderArray(order)
      }
    })
  );
};

export const getInvoiceResources = (merchantId: string, range: DoAppInvoiceDateRange) => {
  return httpRequest.runRequest<GetInvoiceResourcesResponse>(
    doAppMerchantsRequests.getInvoiceResources({
      requestParams: {
        id: merchantId,
        range
      }
    })
  );
};

export const createInvoice = (merchantId: string, range: DoAppInvoiceDateRange) => {
  return httpRequest.runRequest<{ data: { invoiceId: string | null } }>(
    doAppMerchantsRequests.createInvoice({
      requestParams: {
        id: merchantId,
        range
      }
    })
  );
};

export const getOrganizationMerchant = () => {
  return httpRequest.runRequest<GetMerchantResponse>(doAppMerchantsRequests.getOrganizationMerchant());
};

export const updateMerchant = async (merchantId: string, data: UpdateMerchantRequestBody) => {
  return httpRequest.runRequest(
    doAppMerchantsRequests.updateMerchant({
      requestParams: { merchantId },
      requestPayload: data
    })
  );
};

export const merchantSelfConfiguration = async (data: MerchantSelfConfigurationRequestDto) => {
  return httpRequest.runRequest<GetMerchantResponse>(
    doAppMerchantsRequests.merchantSelfConfiguration({
      requestPayload: data
    })
  );
};

export const redeemPromotionalCredits = async (data: { amount: number }) => {
  return httpRequest.runRequest<GetMerchantResponse>(
    doAppMerchantsRequests.redeemPromotionalCredits({
      requestPayload: data
    })
  );
};

export const createCustomerPortalPaymentSession = async () => {
  return httpRequest.runRequest<{ token: string }>(doAppMerchantsRequests.createCustomerPortalPaymentSession());
};
