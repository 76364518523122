import React, { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, List, Paper, Typography } from '@material-ui/core';
import { emailProjectsOperations } from '../../../../../../../store/EmailProjects';
import { EmailProject, EmailProjectAttachment } from '../../../../../../../store/EmailProjects/types';
import { Alert } from '@material-ui/lab';
import Loader from '../../../../../../Shared/Loading/Loader';
import { useTranslation } from 'react-i18next';
import app from '../../../../../../../config/app/app';
import Toast from '../../../../../../Shared/Toast/Toast';
import { EmailAttachment } from '../Helpers/EmailAttachment';
import DeleteAttachmentWindow from '../../Windows/DeleteAttachmentWindow';
import useOpenHandler from '../../../../../../../hooks/useOpenHandler';

type EmailAttachmentsProps = {
  project: EmailProject | null;
};

const EmailAttachments: FunctionComponent<EmailAttachmentsProps> = ({ project }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<EmailProjectAttachment[]>([]);
  const [activeAttachment, setActiveAttachment] = useState<EmailProjectAttachment | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [dragOver, setDragOver] = useState<boolean>(false);
  const [deleteAttachmentWindowOpen, onDeleteAttachmentWindowOpen, onDeleteAttachmentWindowClose] = useOpenHandler();

  const inputAttachmentRef = useRef<HTMLInputElement | null>(null);

  const fetchData = async () => {
    if (project) {
      setLoading(true);
      try {
        const response = await emailProjectsOperations.getAttachments(project.id);
        setAttachments(response.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!project) {
    return null;
  }

  const CenteredLoader = () => (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );

  const containsFiles = (e: any) => {
    if (e && e.dataTransfer && e.dataTransfer.types) {
      for (let i = 0; i < e.dataTransfer.types.length; i++) {
        if (e.dataTransfer.types[i] == 'Files') {
          return true;
        }
      }
    }

    return false;
  };

  const onFileDrop = async (e: any) => {
    try {
      if (containsFiles(e)) {
        let file: File | null = null;
        if (e && e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files[0]) {
          file = e.dataTransfer.files[0];
        }
        if (file) {
          await uploadFile(file);
          await fetchData();
        }
      }
    } catch (e) {
      setProgress(0);
      setUploading(false);
      console.warn('Error occurred while uploading file', e);
    }

    setDragOver(false);
  };

  const uploadFile = async (file: File) => {
    setProgress(0);
    const extension = file.name.split('.').pop();

    if (!app.validEmailAttachmentExtensions.includes((extension || '').toLowerCase())) {
      Toast.error(t('notifications.uploadEmailFile.invalidExtension'));
      setUploading(false);
      setDragOver(false);
      return;
    }

    if (file.size > app.maxLogoSize) {
      Toast.error(t('notifications.uploadEmailFile.fileTooBig'));
      setUploading(false);
      setDragOver(false);
      return;
    }

    setUploading(true);
    await emailProjectsOperations.uploadAttachment(file, project.id, (progress) => {
      setProgress(progress);
    });
    setUploading(false);
  };

  const handleAttachmentUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files && e.target.files[0]) {
      try {
        const file = e.target.files[0];
        await uploadFile(file);
        await fetchData();
      } catch (e) {
        setUploading(false);
        console.warn('Error occurred while uploading file', e);
      }
    }
  };

  const onDeleteAttachmentConfirmed = async () => {
    if (activeAttachment) {
      try {
        await emailProjectsOperations.removeAttachment(activeAttachment.projectId, activeAttachment.id);
        const updatedAttachments = attachments.filter((att) => att.id !== activeAttachment.id);
        setAttachments(updatedAttachments);
        Toast.success(t('notifications.emailAttachments.removeSuccess'));
      } catch (error) {
        Toast.error(t('notifications.emailAttachments.removeError'));
      } finally {
        onDeleteAttachmentWindowClose();
      }
    }
  };

  const EmailAttachmentsList = () => {
    if (loading) {
      return <>{loading && <CenteredLoader />}</>;
    }

    return (
      <>
        {loading && <CenteredLoader />}
        {!loading && (
          <>
            <TitleWrapper>
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ textAlign: 'left', padding: '1rem 1rem 0 1rem', width: '100%' }}
              >
                {t('emailAttachments.listTitle')}
              </Typography>
              <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={() => inputAttachmentRef.current && inputAttachmentRef.current.click()}
                style={{ marginRight: 10, whiteSpace: 'nowrap', padding: '4px 30px' }}
              >
                {t('emailAttachments.addAttachment')}
              </Button>
            </TitleWrapper>
            {attachments.length > 0 && (
              <Alert
                variant="outlined"
                severity="warning"
                style={{ margin: '0 1em', padding: '0 6px', alignItems: 'center' }}
              >
                {t('emailAttachments.info')}
              </Alert>
            )}
            <List style={{ width: '100%' }}>
              {attachments.map((attachment) => (
                <EmailAttachment
                  key={attachment.id}
                  attachment={attachment}
                  removeAttachment={(attachment) => {
                    setActiveAttachment(attachment);
                    onDeleteAttachmentWindowOpen();
                  }}
                />
              ))}
            </List>
            {!loading && attachments.length === 0 && (
              <Alert severity="info" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                {t('emailAttachments.noAttachments')}
              </Alert>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <Paper
      style={{ position: 'relative' }}
      onDragEnter={(e) => {
        if (containsFiles(e)) {
          setDragOver(true);
        }
      }}
    >
      <StyledPaper>
        <EmailAttachmentsList />
      </StyledPaper>
      {(dragOver || uploading) && (
        <DropHereInfo onDrop={(e) => onFileDrop(e)} onDragLeave={() => setDragOver(false)}>
          {uploading
            ? progress === 100
              ? t('common.processing')
              : `${t('imagesPalette.uploading')} (${progress}%)`
            : t('imagesPalette.dropHere')}
        </DropHereInfo>
      )}
      <input
        id="attachmentUploader"
        ref={inputAttachmentRef}
        type="file"
        onChange={handleAttachmentUpload}
        style={{ display: 'none' }}
      />
      {deleteAttachmentWindowOpen && activeAttachment && (
        <DeleteAttachmentWindow
          attachment={activeAttachment}
          open={deleteAttachmentWindowOpen}
          onCloseClick={onDeleteAttachmentWindowClose}
          fullScreenOnMobile={true}
          onFormSubmit={() => onDeleteAttachmentConfirmed()}
        />
      )}
    </Paper>
  );
};

const DropHereInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 1.5rem;
  font-style: italic;
  color: #fff;
  border-radius: 4px;
`;

const StyledPaper = styled(Paper)`
  min-height: 480px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  align-items: center;
`;

const LoaderWrapper = styled.div`
  display: flex;
  height: 20rem;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export default EmailAttachments;
