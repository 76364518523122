import { Dictionary } from 'lodash';
import { StateWithPagination, StateWithTableSearch } from '../../../components/Shared/DataTable/types';

export type DoAppMerchantsState = StateWithPagination &
  StateWithTableSearch<DoAppMerchant> & {
    data: NormalizedDoAppMerchants;

    index: {
      isLoading: boolean;
      isFailed: boolean;
      error: NetworkError | null;
    };
  };

export enum MerchantRewardConfigurationType {
  INVOICE_AUTO_APPLY = 'invoice-auto-apply',
  MANUAL_REDEEM = 'MANUAL_REDEEM'
}

export type DoAppMerchant = {
  id: string;
  name: string;
  billingEmail: string;
  email: string;
  merchantPortalId: string;
  region: string;
  district: string;
  promotionalCredits: number;
  incentiveCredits: number;
  rewardsConfigurationType: MerchantRewardConfigurationType;
  paymentMethodActive: boolean;
};

export enum DoAppInvoiceDateRange {
  THIS_MONTH = 'this-month',
  LAST_MONTH = 'last-month',
  CUSTOM = 'custom'
}

export type NormalizedDoAppMerchants = {
  entities: Dictionary<DoAppMerchant>;
  result: string[];
};

export type DoAppMerchantsIndexResponse = {
  data: DoAppMerchant[];
  pagination: Pagination;
};

export type PaymentProcessorChargeEntry = { amount: number; description: string; date_from?: number; date_to?: number };

export type DoAppInvoiceData = {
  charges: PaymentProcessorChargeEntry[];
  credits: {
    promotional: number;
    incentive: number;
  };
};

export type GetInvoiceResourcesResponse = {
  data: DoAppInvoiceData;
};

export type DoAppMerchantsIndexActionBEGIN = {
  type: DoAppMerchantsActionsConstants.INDEX_BEGIN;
};

export type DoAppMerchantsIndexActionSUCCESS = {
  type: DoAppMerchantsActionsConstants.INDEX_SUCCESS;
  payload: DoAppMerchantsIndexResponse;
};

export type DoAppMerchantsIndexActionFAILED = {
  type: DoAppMerchantsActionsConstants.INDEX_FAILED;
  payload: {
    error: any;
  };
};

export type IndexDoAppMerchantsRequestParams = {
  per: number;
  page: number;
  q: string | null;
  order?: string[];
};

export enum DoAppMerchantsActionsConstants {
  INDEX_BEGIN = 'DO_APP/MERCHANTS/INDEX/BEGIN',
  INDEX_SUCCESS = 'DO_APP/MERCHANTS/INDEX/SUCCESS',
  INDEX_FAILED = 'DO_APP/MERCHANTS/INDEX/FAILED'
}

export type DoAppMerchantsActionType =
  | DoAppMerchantsIndexActionBEGIN
  | DoAppMerchantsIndexActionSUCCESS
  | DoAppMerchantsIndexActionFAILED;

export type GetMerchantResponse = {
  data: DoAppMerchant;
  meta?: {
    creditTransactionsAmount: number;
    subscribersEngaged: number;
  };
};

export type UpdateMerchantRequestBody = {
  name?: string;
  region?: string;
  district?: string;
  billingEmail?: string;
  rewardConfigurationType?: MerchantRewardConfigurationType;
};

export type MerchantSelfConfigurationRequestDto = {
  rewardConfigurationType?: MerchantRewardConfigurationType;
};
